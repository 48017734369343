@if (breadcrumbs(); as breadcrumbs) {
	<cjm-layout-container layoutType="full-width" [preventSpacing]="['top', 'bottom']">
		@if (breadcrumbs.length > 0) {
			<ng-container main>
				<span class="cdk-visually-hidden">{{ i18nKeys.Breadcrumb.AccessibleTitle | translate }}</span>
				<ul class="c-page-overview c-page-overview--{{ position() }}">
					@for (route of breadcrumbs; track route; let last = $last) {
						<li class="c-page-overview__item">
							@if (route.allowNav) {
								<a
									class="c-page-overview__link u-font-bold"
									cypressTag="Breadcrumbs.Breadcrumb"
									[class.is-active]="last"
									[routerLink]="route.routePath"
									[title]="route.title | translate"
								>
									<ng-container *mediaQuery="mediaQueryMin.Desktop">
										{{ route.title | translate }}
									</ng-container>
									<ng-container *mediaQuery="mediaQueryMax.Desktop">
										{{ (route.mobileTitle ? route.mobileTitle : route.title) | translate }}
									</ng-container>
								</a>
							}
							@if (!route.allowNav) {
								<a
									class="c-page-overview__link u-font-bold"
									[class.is-active]="last"
									[title]="route.title | translate"
								>
									<ng-container *mediaQuery="mediaQueryMin.Desktop">
										{{ route.title | translate }}
									</ng-container>
									<ng-container *mediaQuery="mediaQueryMax.Desktop">
										{{ (route.mobileTitle ? route.mobileTitle : route.title) | translate }}
									</ng-container>
								</a>
							}
						</li>
					}
				</ul>
			</ng-container>
		}
	</cjm-layout-container>
}
