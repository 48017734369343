import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@cjm/cypress/core';
import { MediaQueryMax, MediaQueryMin } from '@cjm/shared/types';
import { LayoutContainerComponent } from '@cjm/shared/ui/common';
import { MediaQueryDirective } from '@cjm/shared/ui/device';

import { Breadcrumb, BreadcrumbService } from '../../../data';
import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'cjm-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['breadcrumb.component.scss'],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		'[class.c-breadcrumb]': 'true',
		'[class.c-breadcrumb--absolute]': 'position() === "absolute"',
		'[class.c-breadcrumb--static]': 'position() === "static"'
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [LayoutContainerComponent, CypressTagDirective, RouterLink, MediaQueryDirective, TranslateModule]
})
export class BreadcrumbComponent {
	/**
	 * The breadcrumbs that currently need displaying.
	 */
	public readonly breadcrumbs: Signal<Breadcrumb[]> = toSignal(this.breadcrumbService.breadcrumbs$);
	public readonly position: Signal<'static' | 'absolute'> = toSignal(this.breadcrumbService.position$);
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	constructor(private readonly breadcrumbService: BreadcrumbService) {}
}
