import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@cjm/shared/i18n';

export const I18nKeys = {
	...sharedI18nKeys,
	Breadcrumb: {
		AccessibleTitle: marker('breadcrumb.accessible-title')
	}
};
